<template lang="pug">
	div
		.widget
			.widget-header
				h1.title Admin Info.
			.widget-body
				form(@submit="submit")
					input(type="text", name="email", style="display: none;")
					input(type="password", name="password", style="display: none;")
					.row
						.col-sm-4(v-if="admin.id")
							.form-group
								label ID
								input.form-control(type="text", disabled="disabled", :value="admin.id")
						.col-sm-4
							.form-group
								label Username
								input.form-control(type="text", v-model="newUsername")
								small.form-text.text-muted Current username is "{{ admin.username }}". Leave blank to keep current value
						.col-sm-4
							.form-group
								label Password
								input.form-control(type="password", v-model="newPassword", autocomplete="false")
								small.form-text.text-muted Leave blank to keep current value
					.buttons.text-right
						router-link.btn.btn-default(:to="{name:'admin-list'}")
							i.la.la-angle-left
							| Back
						| &nbsp;
						button.btn.btn-primary(type="submit") Save
							i.la.la-fw.la-save
</template>
<script>
import Vue from 'vue';

export default {
  name: 'AdminForm',
  async beforeRouteEnter(to, from, next) {
    let admin = {};
    if (to.name === 'admin-edit') {
      let entityId = +to.params.id;
      try {
        let resp = await Vue.ovReq.get('admin/get/' + entityId);
        admin = resp.entity;
      } catch (e) {
        Vue.ovNotify.error('Failed to get admin');
        return next(false);
      }
    } else {
      admin = {
        id: 0,
        username: '',
        password: '',
      };
    }
    next((vm) => {
      vm.admin = admin;
      return vm;
    });
  },
  data() {
    return {
      newUsername: '',
      newPassword: '',
      admin: {},
    };
  },
  methods: {
    submit() {
      console.log('submit');
    },
  },
};
</script>
